import { call, put, select, take, takeLatest } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import { GET_EVENT_SETTINGS_REQUEST, GET_EVENT_SETTINGS_SUCCESS, GET_EVENT_SETTINGS_ERROR, INIT_API } from "./actions";
import { Amplify, API, graphqlOperation } from "aws-amplify";
import { eventByUrl, getEvent, getEventByUrl } from "../../graphql/queries";
import { onUpdateEvent, onEventUpdateById } from "../../graphql/subscriptions";
import { GET_CURRENT_USER, INIT_AUTH } from "../auth/actions";

export const getCurrentEvent = (state) => state.event.currentEvent;

function createSub(subscription) {
    return new eventChannel((emit) => {
        const update = (newEvent) => emit(newEvent);
        subscription.subscribe({ next: update });
        return () => {
            subscription.unsubscribe();
        };
    });
}

function* subscribeToAPISaga({ data: event }) {
    //TODO:Create subscriptions with each event and let them update state
    //Create one action for event_created, event_updated, event_deleted
    //Each time their subscription get fired do releavent yield put
    //CODE BELOW IS WORKING WITH createSub function above.!!!!!!!!
    const updateSub = yield call([API, "graphql"], graphqlOperation(onEventUpdateById, { id: event.id }));
    const updateChannel = yield call(createSub, updateSub);
    try {
        while (true) {
            const updated = yield take(updateChannel);
            yield put({
                type: GET_EVENT_SETTINGS_SUCCESS,
                data: updated.value.data.onEventUpdateById,
            });
        }
    } catch (error) {
        console.log(error);
    }
}

function* getEventSaga({ data: { url, history } }) {
    console.log(url);
    try {
        const response = yield call(
            [API, "graphql"],
            graphqlOperation(getEventByUrl, {
                url: url,
            })
        );
        const event = response.data.getEventByUrl;
        if (event.needUserAuth) {
            yield put({
                type: INIT_AUTH,
                data: { userPoolId: event.userPoolId, userPoolWebClientId: event.userPoolWebClientId },
            });
        }
        yield put({
            type: GET_EVENT_SETTINGS_SUCCESS,
            data: event,
        });
    } catch (error) {
        console.log(error);
    }
}
function* initAPI() {
    try {
        Amplify.configure({
            aws_appsync_graphqlEndpoint:
                "https://jk3p36css5a4bkxqtdns2g3ryi.appsync-api.eu-central-1.amazonaws.com/graphql",
            aws_appsync_region: "eu-central-1",
            aws_appsync_authenticationType: "API_KEY",
            aws_appsync_apiKey: "da2-5jpyjp2fl5gobgttqmwkyw2bhu",
        });
    } catch (error) {
        console.log(error);
    }
}
export default function* eventSaga() {
    yield takeLatest(INIT_API, initAPI);
    yield takeLatest(GET_EVENT_SETTINGS_REQUEST, getEventSaga);
    yield takeLatest(GET_EVENT_SETTINGS_SUCCESS, subscribeToAPISaga);
    yield put({ type: INIT_API });
    yield put({
        type: GET_EVENT_SETTINGS_REQUEST,
        data: {
            url:
                process.env.NODE_ENV === "development"
                    ? "https://hurraaa.mice.zone"
                    :  window.location.hostname,
        },
    });
}
