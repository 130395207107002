/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onEventUpdateById = /* GraphQL */ `
    subscription OnEventUpdateById($id: ID!) {
        onEventUpdateById(id: $id) {
            id
            name
            url
            isOnline
            testModeEnabled
            needUserAuth
            countdownEnabled
            customDomainEnabled
            qaEnabled
            chatEnabled
            pollsEnabled
            multilanguage
            defaultEmbedID
            date
            registrationEnabled
            passwordEnabled
            loginEnabled
            userFields
            fromMail
            createdUserTemplate {
                id
                eventID
                type
                locale
                template
                createdAt
                updatedAt
            }
            confirmationTemplate {
                id
                eventID
                type
                locale
                template
                createdAt
                updatedAt
            }
            verificationTemplate {
                id
                eventID
                type
                locale
                template
                createdAt
                updatedAt
            }
            resendVerificationTemplate {
                id
                eventID
                type
                locale
                template
                createdAt
                updatedAt
            }
            forgotPasswordTemplate {
                id
                eventID
                type
                locale
                template
                createdAt
                updatedAt
            }
            userPoolId
            userPoolWebClientId
            githubRepo
            createdAt
            updatedAt
            defaultEmbed {
                id
                eventID
                URL
                createdAt
                updatedAt
            }
            embeds {
                nextToken
            }
            messages {
                nextToken
            }
            users {
                nextToken
            }
        }
    }
`;
export const onCreateEvent = /* GraphQL */ `
    subscription OnCreateEvent {
        onCreateEvent {
            id
            name
            url
            isOnline
            testModeEnabled
            needUserAuth
            countdownEnabled
            customDomainEnabled
            qaEnabled
            chatEnabled
            pollsEnabled
            multilanguage
            defaultEmbedID
            date
            registrationEnabled
            passwordEnabled
            loginEnabled
            userFields
            fromMail
            createdUserTemplate {
                id
                eventID
                type
                locale
                template
                createdAt
                updatedAt
            }
            confirmationTemplate {
                id
                eventID
                type
                locale
                template
                createdAt
                updatedAt
            }
            verificationTemplate {
                id
                eventID
                type
                locale
                template
                createdAt
                updatedAt
            }
            resendVerificationTemplate {
                id
                eventID
                type
                locale
                template
                createdAt
                updatedAt
            }
            forgotPasswordTemplate {
                id
                eventID
                type
                locale
                template
                createdAt
                updatedAt
            }
            userPoolId
            userPoolWebClientId
            githubRepo
            createdAt
            updatedAt
            defaultEmbed {
                id
                eventID
                URL
                createdAt
                updatedAt
            }
            embeds {
                nextToken
            }
            messages {
                nextToken
            }
            users {
                nextToken
            }
        }
    }
`;
export const onUpdateEvent = /* GraphQL */ `
    subscription OnUpdateEvent {
        onUpdateEvent {
            id
            name
            url
            isOnline
            testModeEnabled
            needUserAuth
            countdownEnabled
            customDomainEnabled
            qaEnabled
            chatEnabled
            pollsEnabled
            multilanguage
            defaultEmbedID
            date
            registrationEnabled
            passwordEnabled
            loginEnabled
            userFields
            fromMail
            createdUserTemplate {
                id
                eventID
                type
                locale
                template
                createdAt
                updatedAt
            }
            confirmationTemplate {
                id
                eventID
                type
                locale
                template
                createdAt
                updatedAt
            }
            verificationTemplate {
                id
                eventID
                type
                locale
                template
                createdAt
                updatedAt
            }
            resendVerificationTemplate {
                id
                eventID
                type
                locale
                template
                createdAt
                updatedAt
            }
            forgotPasswordTemplate {
                id
                eventID
                type
                locale
                template
                createdAt
                updatedAt
            }
            userPoolId
            userPoolWebClientId
            githubRepo
            createdAt
            updatedAt
            defaultEmbed {
                id
                eventID
                URL
                createdAt
                updatedAt
            }
            embeds {
                nextToken
            }
            messages {
                nextToken
            }
            users {
                nextToken
            }
        }
    }
`;
export const onDeleteEvent = /* GraphQL */ `
    subscription OnDeleteEvent {
        onDeleteEvent {
            id
            name
            url
            isOnline
            testModeEnabled
            needUserAuth
            countdownEnabled
            customDomainEnabled
            qaEnabled
            chatEnabled
            pollsEnabled
            multilanguage
            defaultEmbedID
            date
            registrationEnabled
            passwordEnabled
            loginEnabled
            userFields
            fromMail
            createdUserTemplate {
                id
                eventID
                type
                locale
                template
                createdAt
                updatedAt
            }
            confirmationTemplate {
                id
                eventID
                type
                locale
                template
                createdAt
                updatedAt
            }
            verificationTemplate {
                id
                eventID
                type
                locale
                template
                createdAt
                updatedAt
            }
            resendVerificationTemplate {
                id
                eventID
                type
                locale
                template
                createdAt
                updatedAt
            }
            forgotPasswordTemplate {
                id
                eventID
                type
                locale
                template
                createdAt
                updatedAt
            }
            userPoolId
            userPoolWebClientId
            githubRepo
            createdAt
            updatedAt
            defaultEmbed {
                id
                eventID
                URL
                createdAt
                updatedAt
            }
            embeds {
                nextToken
            }
            messages {
                nextToken
            }
            users {
                nextToken
            }
        }
    }
`;
export const onCreateEmailTemplate = /* GraphQL */ `
    subscription OnCreateEmailTemplate {
        onCreateEmailTemplate {
            id
            eventID
            type
            locale
            template
            createdAt
            updatedAt
        }
    }
`;
export const onUpdateEmailTemplate = /* GraphQL */ `
    subscription OnUpdateEmailTemplate {
        onUpdateEmailTemplate {
            id
            eventID
            type
            locale
            template
            createdAt
            updatedAt
        }
    }
`;
export const onDeleteEmailTemplate = /* GraphQL */ `
    subscription OnDeleteEmailTemplate {
        onDeleteEmailTemplate {
            id
            eventID
            type
            locale
            template
            createdAt
            updatedAt
        }
    }
`;
export const onCreateEmbed = /* GraphQL */ `
    subscription OnCreateEmbed {
        onCreateEmbed {
            id
            eventID
            URL
            createdAt
            updatedAt
        }
    }
`;
export const onUpdateEmbed = /* GraphQL */ `
    subscription OnUpdateEmbed {
        onUpdateEmbed {
            id
            eventID
            URL
            createdAt
            updatedAt
        }
    }
`;
export const onDeleteEmbed = /* GraphQL */ `
    subscription OnDeleteEmbed {
        onDeleteEmbed {
            id
            eventID
            URL
            createdAt
            updatedAt
        }
    }
`;
export const onCreateMessage = /* GraphQL */ `
    subscription OnCreateMessage {
        onCreateMessage {
            id
            messageID
            eventID
            userID
            content
            approved
            archived
            hidden
            sentAt
            sentByIP
            createdAt
            updatedAt
            sentBy {
                id
                eventID
                eventGroup
                eventGroupAdmin
                name
                email
                phone
                company
                region
                country
                comment
                locale
                confirmed
                lastLogin
                lastLoginIP
                createdAt
                confirmedAt
                onlineStatus
                updatedAt
            }
        }
    }
`;
export const onUpdateMessage = /* GraphQL */ `
    subscription OnUpdateMessage {
        onUpdateMessage {
            id
            messageID
            eventID
            userID
            content
            approved
            archived
            hidden
            sentAt
            sentByIP
            createdAt
            updatedAt
            sentBy {
                id
                eventID
                eventGroup
                eventGroupAdmin
                name
                email
                phone
                company
                region
                country
                comment
                locale
                confirmed
                lastLogin
                lastLoginIP
                createdAt
                confirmedAt
                onlineStatus
                updatedAt
            }
        }
    }
`;
export const onDeleteMessage = /* GraphQL */ `
    subscription OnDeleteMessage {
        onDeleteMessage {
            id
            messageID
            eventID
            userID
            content
            approved
            archived
            hidden
            sentAt
            sentByIP
            createdAt
            updatedAt
            sentBy {
                id
                eventID
                eventGroup
                eventGroupAdmin
                name
                email
                phone
                company
                region
                country
                comment
                locale
                confirmed
                lastLogin
                lastLoginIP
                createdAt
                confirmedAt
                onlineStatus
                updatedAt
            }
        }
    }
`;
export const onCreateUser = /* GraphQL */ `
    subscription OnCreateUser {
        onCreateUser {
            id
            eventID
            eventGroup
            eventGroupAdmin
            name
            email
            phone
            company
            region
            country
            comment
            locale
            confirmed
            lastLogin
            lastLoginIP
            createdAt
            confirmedAt
            onlineStatus
            updatedAt
            event {
                id
                name
                url
                isOnline
                testModeEnabled
                needUserAuth
                countdownEnabled
                customDomainEnabled
                qaEnabled
                chatEnabled
                pollsEnabled
                multilanguage
                defaultEmbedID
                date
                registrationEnabled
                passwordEnabled
                loginEnabled
                userFields
                fromMail
                userPoolId
                userPoolWebClientId
                githubRepo
                createdAt
                updatedAt
            }
            messages {
                nextToken
            }
        }
    }
`;
export const onUpdateUser = /* GraphQL */ `
    subscription OnUpdateUser {
        onUpdateUser {
            id
            eventID
            eventGroup
            eventGroupAdmin
            name
            email
            phone
            company
            region
            country
            comment
            locale
            confirmed
            lastLogin
            lastLoginIP
            createdAt
            confirmedAt
            onlineStatus
            updatedAt
            event {
                id
                name
                url
                isOnline
                testModeEnabled
                needUserAuth
                countdownEnabled
                customDomainEnabled
                qaEnabled
                chatEnabled
                pollsEnabled
                multilanguage
                defaultEmbedID
                date
                registrationEnabled
                passwordEnabled
                loginEnabled
                userFields
                fromMail
                userPoolId
                userPoolWebClientId
                githubRepo
                createdAt
                updatedAt
            }
            messages {
                nextToken
            }
        }
    }
`;
export const onDeleteUser = /* GraphQL */ `
    subscription OnDeleteUser {
        onDeleteUser {
            id
            eventID
            eventGroup
            eventGroupAdmin
            name
            email
            phone
            company
            region
            country
            comment
            locale
            confirmed
            lastLogin
            lastLoginIP
            createdAt
            confirmedAt
            onlineStatus
            updatedAt
            event {
                id
                name
                url
                isOnline
                testModeEnabled
                needUserAuth
                countdownEnabled
                customDomainEnabled
                qaEnabled
                chatEnabled
                pollsEnabled
                multilanguage
                defaultEmbedID
                date
                registrationEnabled
                passwordEnabled
                loginEnabled
                userFields
                fromMail
                userPoolId
                userPoolWebClientId
                githubRepo
                createdAt
                updatedAt
            }
            messages {
                nextToken
            }
        }
    }
`;
