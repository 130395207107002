import React from "react";
import { Row, Form, Input, Button } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { resetPasswordRequest } from "../../store/actions";
import "./Login.less";

const ResetPassword = (props) => {
    const handleValidSubmit = (values) => {
        //TODO:CHECK IF BOTH PASSWORDS ARE SAME
        props.resetPasswordRequest(values.password, props.history);
    };
    return (
        <React.Fragment>
            <div className="login-form">
                <div className="login-form-logo">
                    <span>mice.zone</span>
                </div>
                <Form onFinish={handleValidSubmit}>
                    <Form.Item
                        name="password"
                        rules={[{ required: true }]}
                        hasFeedback
                    >
                        <Input type="password" placeholder="Password" />
                    </Form.Item>
                    <Form.Item
                        name="password2"
                        rules={[{ required: true }]}
                        hasFeedback
                    >
                        <Input type="password" placeholder="Password" />
                    </Form.Item>
                    <Row>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={props.loading}
                        >
                            Sign In
                        </Button>
                    </Row>
                </Form>
            </div>
        </React.Fragment>
    );
};

const mapStatetoProps = (state) => {
    const { error, loading } = state.auth;
    return { error, loading };
};

export default withRouter(
    connect(mapStatetoProps, { resetPasswordRequest })(ResetPassword)
);
