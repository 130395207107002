import React from "react";
import { Row, Form, Input, Button } from "antd";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import { loginRequest } from "../../store/actions";
import "./Login.less";

const Login = (props) => {
    const history = useHistory();
    const handleValidSubmit = (values) => {
        props.loginRequest(values, history);
    };
    return (
        <React.Fragment>
            <div className="login-form">
                <div className="login-form-logo">
                    <span>mice.zone</span>
                </div>
                <Form onFinish={handleValidSubmit}>
                    <Form.Item
                        name="username"
                        rules={[{ required: true }]}
                        hasFeedback
                    >
                        <Input placeholder="Username" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{ required: true }]}
                        hasFeedback
                    >
                        <Input type="password" placeholder="Password" />
                    </Form.Item>
                    <Row>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={props.loading}
                        >
                            Sign In
                        </Button>
                    </Row>
                </Form>
            </div>
        </React.Fragment>
    );
};

const mapStatetoProps = (state) => {
    const { error, loading } = state.auth;
    return { error, loading };
};

export default withRouter(connect(mapStatetoProps, { loginRequest })(Login));
