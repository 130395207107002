import { Redirect } from "react-router-dom";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ResetPassword from "../pages/Authentication/ResetPassword";
import Event from "../pages/Event";
import Pages404 from "../pages/Shared/Pages404";
import PagesMaintenance from "../pages/Shared/PagesMaintenance";
import PagesComingSoon from "../pages/Shared/PagesComingsoon";
const authProtectedRoutes = [{ path: "/", exact: true, component: Event }];

const publicRoutes = [
    { path: "/logout", component: Logout },
    { path: "/login", component: Login },
    { path: "/reset-password", component: ResetPassword },
    //{ path: "/forget-password", component: ForgetPwd },
    //{ path: "/register", component: Register },
    { path: "/pages-404", component: Pages404 },
    { path: "/coming-soon", component: PagesComingSoon },
    { path: "/pages-Maintenance", component: PagesMaintenance },
];

export { authProtectedRoutes, publicRoutes };
