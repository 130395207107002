import React, { Component } from "react";
import { Row, Col, Card } from "antd";
import { Link } from "react-router-dom";

// img

class Pages404 extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <React.Fragment>
                <div className="authentication-bg d-flex align-items-center pb-0 vh-100">
                    <div className="content-center w-100">
                        <div className="container">
                            <Card className="mo-mt-2">
                                <Row className="align-items-center">
                                    <Col lg="4" className="ml-auto">
                                        <div className="ex-page-content">
                                            <h1 className="text-dark display-1 mt-4">
                                                404!
                                            </h1>
                                            <h4 className="mb-4">
                                                Sorry, page not found
                                            </h4>
                                            <p className="mb-5">
                                                It will be as simple as
                                                Occidental in fact, it will be
                                                Occidental to an English person
                                            </p>
                                        </div>
                                    </Col>
                                    <Col lg="5" className="mx-auto">
                                        <img
                                            alt=""
                                            className="img-fluid mx-auto d-block"
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Pages404;
