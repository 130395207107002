import React from "react";
import { Layout, Row, Typography,Space } from "antd";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "./Event.less";
import Player from "./Player";
const { Title } = Typography;

const Event = ({ event }) => {
    return (
        <>
            <Layout className="layout">
                <Layout.Header className="header"></Layout.Header>
                <Layout.Content className="container">
                    <Row>
                        <Player event={event} />
                    </Row>
                </Layout.Content>
                <Layout.Footer className="footer">
                    <Space className="logo">
                        <Title className="logobig">L</Title>
                        <Title className="logosmall">ive</Title>
                        <Title className="logobig">V</Title>
                        <Title className="logosmall">irtual</Title>
                        <Title className="logobig">A</Title>
                        <Title className="logosmall">ctivities</Title>
                    </Space>
                </Layout.Footer>
            </Layout>
        </>
    );
};

const mapStateToProps = (state) => ({
    event: state.event.currentEvent,
});

export default withRouter(connect(mapStateToProps, null)(Event));
