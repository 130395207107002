import React from "react";
import { Row, Col } from "antd";
import Countdown from "react-countdown-now";
import "./PagesComingsoon.less";
import { connect } from "react-redux";

const renderer = ({ days, hours, minutes, seconds, completed }) => {
    return (
        <div className="countdown-container">
            <div className="countdown-container-center">
                <span className="coming-soon">Coming Soon!</span>
                <Row type="flex" justify="center" align="middle">
                    <Col span={6}>
                        <div className="number">
                            <span>{days}</span>
                            <span className="static">Days</span>
                        </div>
                    </Col>
                    <Col span={5} offset={1}>
                        <div className="number">
                            <span>{hours}</span>
                            <span className="static">Hours</span>
                        </div>
                    </Col>
                    <Col span={5} offset={1}>
                        <div className="number">
                            <span>{minutes}</span>
                            <span className="static">Minutes</span>
                        </div>
                    </Col>
                    <Col span={5} offset={1}>
                        <div className="number">
                            <span>{seconds}</span>
                            <span className="static">Seconds</span>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

const PagesComingsoon = (props) => {
    return (
        <>
            <p>{process.env.TEST_VARIABLE}</p>
            <Countdown date={Date.parse(props.event.currentEvent.date)} renderer={renderer} daysInHours={false} />
        </>
    );
};

const mapStateToProps = (state) => ({
    event: state.event,
});

export default connect(mapStateToProps, null)(PagesComingsoon);
