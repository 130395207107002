import React, { useState } from "react";
import { Col, Popover, Button, Space, Card, Form, Input } from "antd";
import ReactPlayer from "react-player";
import {
    WechatOutlined,
    ArrowRightOutlined,
    QuestionOutlined,
    CloseOutlined,
} from "@ant-design/icons";
import "./Player.less";

const Player = ({ event }) => {
    const [widths, setWidths] = useState([21, 0]);
    const [visible, setVisible] = useState({});
    const toggleFeature = (id) => {
        if (id === "chat" && visible[id]) {
            setTimeout(() => {
                setWidths([21, 0]);
            }, 500);
        } else if (id === "chat") {
            setWidths([17, 4]);
        }
        setTimeout(() => {
            setVisible((prevState) => ({ ...prevState, [id]: !prevState[id] }));
        }, 500);
    };
    return (
        <>
            <Col span={widths[0]} className="video">
                <div className="player-wrapper">
                    <div className="triple-spinner spinner-fix" />
                    <div className="aspect-spacer"></div>
                    {event.chatEnabled && (
                        <Popover content={<div>test</div>} visible={false}>
                            <Button
                                className="chat-collapse-button"
                                onClick={() => toggleFeature("chat")}
                            >
                                {!visible["chat"] ? (
                                    <span>
                                        <Space>
                                            Chat
                                            <WechatOutlined />
                                        </Space>
                                    </span>
                                ) : (
                                    <ArrowRightOutlined />
                                )}
                            </Button>
                        </Popover>
                    )}
                    {visible["question"] && (
                        <Card
                            className="question-container"
                            title="Soru"
                            headStyle={{
                                color: "#fff",
                                minHeight: "0px",
                            }}
                            style={{ padding: "0px" }}
                        >
                            <span>
                                Quis nulla deserunt ad dolor ullamco consequat
                                amet tempor nostrud duis ullamco nostrud.
                                <br /> <br />
                                Mollit voluptate minim officia voluptate in nisi
                                ex quis aliqua pariatur aliquip excepteur
                                commodo duis.
                                <br /> <br />
                                Excepteur nostrud consectetur consequat culpa.
                                <br /> <br />
                                Mollit voluptate minim officia voluptate in nisi
                                ex quis aliqua pariatur aliquip excepteur
                                commodo duis.
                            </span>
                        </Card>
                    )}
                    <ReactPlayer
                        className="react-player"
                        muted={true}
                        playing={true}
                        url="https://player.vimeo.com/video/482522059"
                    />
                </div>
                <Form
                    className="qabox"
                    style={{
                        opacity: visible["qa"] ? "1.0" : "0",
                    }}
                >
                    <Form.Item>
                        <Input.Search
                            className="qabox-input"
                            placeholder="Sorunuzu buraya yazabilirsiniz"
                            enterButton="Gönder"
                            // onSearch={soruGonder}
                            // loading={soruButton}
                        />
                    </Form.Item>
                </Form>
                <Button
                    className="question-collapse-button"
                    onClick={() => toggleFeature("qa")}
                >
                    <span>
                        {!visible["qa"] ? (
                            <span>
                                <Space>
                                    <QuestionOutlined />
                                </Space>
                            </span>
                        ) : (
                            <CloseOutlined />
                        )}
                    </span>
                </Button>
            </Col>
            <Col
                span={widths[1]}
                className="chat-container"
                style={{ opacity: visible["chat"] ? "1.0" : "0" }}
            >
                <Card className="chat-box">
                    <Form>
                        <Form.Item className="chat-form">
                            <Input.TextArea
                                className="chat-input"
                                placeholder="Buraya mesajınızı yazabilirsiniz"
                            />
                            <Button className="chat-send-button">Gönder</Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
        </>
    );
};

export default Player;
