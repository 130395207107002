/**
 * ACTION TYPES
 */
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_REQUEST_SUCCESS = "LOGIN_REQUEST_SUCCESS";
export const LOGIN_FORCE_CHANGE_PASSWORD = "LOGIN_FORCE_CHANGE_PASSWORD";
export const LOGIN_REQUEST_ERROR = "LOGIN_REQUEST_ERROR";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_REQUEST_SUCCESS = "LOGOUT_REQUEST_SUCCESS";
export const GET_CURRENT_USER = "GET_CURRENT_USER";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const NO_CURRENT_USER = "NO_CURRENT_USER";
export const CLEAR_CURRENT_USER = "CLEAR_CURRET_USER";

export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_RECEIVE = "SIGNUP_RECEIVE";
export const CONFIRM_SIGNUP_REQUEST = "CONFIRM_SIGNUP_REQUEST";
export const CONFIRM_SIGNUP_RECEIVE = "CONFIRM_SIGNUP_RECEIVE";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const INIT_AUTH = "INIT_AUTH";
/**
 * ACTION CREATORS
 */
export const loginRequest = (user, history) => ({
    type: LOGIN_REQUEST,
    data: { user, history },
});

export const loginError = (error) => ({
    type: LOGIN_REQUEST_ERROR,
    payload: error,
});

export const logoutRequest = (history) => ({
    type: LOGOUT_REQUEST,
    data: history,
});
export const getCurrentUser = () => ({ type: GET_CURRENT_USER });

export const setCurrentUser = (user) => ({
    type: SET_CURRENT_USER,
    data: user,
});

export const signupRequest = (data) => ({ type: SIGNUP_REQUEST, data });
export const signupReceive = (data) => ({ type: SIGNUP_RECEIVE, data });
export const confirmSignupRequest = (data) => ({
    type: CONFIRM_SIGNUP_REQUEST,
    data,
});
export const confirmSignupReceive = (data) => ({
    type: CONFIRM_SIGNUP_RECEIVE,
    data,
});

export const forceChangePassword = (user) => ({
    type: LOGIN_FORCE_CHANGE_PASSWORD,
    data: user,
});

export const resetPasswordRequest = (password, history) => ({
    type: RESET_PASSWORD_REQUEST,
    data: { password, history },
});

export const initAuth = (userPoolId, userPoolWebClientId) => ({
    type: INIT_AUTH,
    data: { userPoolId, userPoolWebClientId },
});
