import { combineReducers } from "redux";

// Authentication
import auth from "./auth/reducer";
import event from "./event/reducer";

const rootReducer = combineReducers({
    // public
    event,
    auth,
});

export default rootReducer;
