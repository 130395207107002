/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getEventByUrl = /* GraphQL */ `
    query GetEventByUrl($url: String!) {
        getEventByUrl(url: $url) {
            id
            name
            isOnline
            testModeEnabled
            needUserAuth
            countdownEnabled
            customDomainEnabled
            qaEnabled
            chatEnabled
            pollsEnabled
            multilanguage
            defaultEmbedID
            date
            registrationEnabled
            passwordEnabled
            loginEnabled
            userPoolId
            userPoolWebClientId
        }
    }
`;

export const listEvents = /* GraphQL */ `
    query ListEvents($filter: ModelEventFilterInput, $limit: Int, $nextToken: String) {
        listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                name
                url
                isOnline
                testModeEnabled
                needUserAuth
                countdownEnabled
                customDomainEnabled
                qaEnabled
                chatEnabled
                pollsEnabled
                multilanguage
                defaultEmbedID
                date
                registrationEnabled
                passwordEnabled
                loginEnabled
                userFields
                fromMail
                userPoolId
                userPoolWebClientId
                githubRepo
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;
export const usersByEvent = /* GraphQL */ `
    query UsersByEvent(
        $eventID: ID
        $sortDirection: ModelSortDirection
        $filter: ModelUserFilterInput
        $limit: Int
        $nextToken: String
    ) {
        usersByEvent(
            eventID: $eventID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                eventID
                eventGroup
                eventGroupAdmin
                name
                email
                phone
                company
                region
                country
                comment
                locale
                confirmed
                lastLogin
                lastLoginIP
                createdAt
                confirmedAt
                onlineStatus
                updatedAt
            }
            nextToken
        }
    }
`;

export const getEvent = /* GraphQL */ `
    query GetEvent($id: ID!) {
        getEvent(id: $id) {
            id
            name
            url
            isOnline
            testModeEnabled
            needUserAuth
            countdownEnabled
            customDomainEnabled
            qaEnabled
            chatEnabled
            pollsEnabled
            multilanguage
            defaultEmbedID
            date
            registrationEnabled
            passwordEnabled
            loginEnabled
            userFields
            userPoolId
            userPoolWebClientId
            githubRepo
            defaultEmbed {
                id
                eventID
                URL
                createdAt
                updatedAt
            }
            embeds {
                nextToken
            }
        }
    }
`;
export const getEmbed = /* GraphQL */ `
    query GetEmbed($id: ID!) {
        getEmbed(id: $id) {
            id
            eventID
            URL
            createdAt
            updatedAt
        }
    }
`;
export const listEmbeds = /* GraphQL */ `
    query ListEmbeds($filter: ModelEmbedFilterInput, $limit: Int, $nextToken: String) {
        listEmbeds(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                eventID
                URL
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;
export const embedsByEvent = /* GraphQL */ `
    query EmbedsByEvent(
        $eventID: ID
        $sortDirection: ModelSortDirection
        $filter: ModelEmbedFilterInput
        $limit: Int
        $nextToken: String
    ) {
        embedsByEvent(
            eventID: $eventID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                eventID
                URL
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;
export const getMessage = /* GraphQL */ `
    query GetMessage($id: ID!) {
        getMessage(id: $id) {
            id
            messageID
            eventID
            userID
            content
            approved
            archived
            hidden
            sentAt
            sentByIP
            createdAt
            updatedAt
            sentBy {
                id
                eventID
                eventGroup
                eventGroupAdmin
                name
                email
                phone
                company
                region
                country
                comment
                locale
                confirmed
                lastLogin
                lastLoginIP
                createdAt
                confirmedAt
                onlineStatus
                updatedAt
            }
        }
    }
`;
export const listMessages = /* GraphQL */ `
    query ListMessages($filter: ModelMessageFilterInput, $limit: Int, $nextToken: String) {
        listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                messageID
                eventID
                userID
                content
                approved
                archived
                hidden
                sentAt
                sentByIP
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;
export const messagesByEvent = /* GraphQL */ `
    query MessagesByEvent(
        $eventID: ID
        $sortDirection: ModelSortDirection
        $filter: ModelMessageFilterInput
        $limit: Int
        $nextToken: String
    ) {
        messagesByEvent(
            eventID: $eventID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                messageID
                eventID
                userID
                content
                approved
                archived
                hidden
                sentAt
                sentByIP
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;
