import { FormProvider } from "antd/lib/form/context";
import { GET_EVENT_SETTINGS_REQUEST, GET_EVENT_SETTINGS_SUCCESS, GET_EVENT_SETTINGS_ERROR, INIT_API } from "./actions";

const initialState = {
    currentEvent: null,
    error: null,
    loading: true,
};

export default (state = initialState, { type, data }) => {
    switch (type) {
        case GET_EVENT_SETTINGS_REQUEST:
            return { ...state, loading: true };
        case GET_EVENT_SETTINGS_SUCCESS:
            return { ...state, currentEvent: data, loading: false };
        case GET_EVENT_SETTINGS_ERROR:
            return { ...state, error: data, loading: false };
        default:
            return { ...state };
    }
};
