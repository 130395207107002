import { call, put, select, takeLatest } from "redux-saga/effects";
import {
    LOGIN_REQUEST,
    LOGIN_REQUEST_SUCCESS,
    LOGIN_REQUEST_ERROR,
    LOGOUT_REQUEST,
    GET_CURRENT_USER,
    SET_CURRENT_USER,
    NO_CURRENT_USER,
    CLEAR_CURRENT_USER,
    RESET_PASSWORD_REQUEST,
    LOGIN_FORCE_CHANGE_PASSWORD,
    LOGOUT_REQUEST_SUCCESS,
    INIT_AUTH,
} from "./actions";
import Amplify, { Auth } from "aws-amplify";
export const currentUser = (state) => state.auth.currentUser;

function* loginRequest({ data: { user, history } }) {
    try {
        const login = yield call([Auth, "signIn"], user.username, user.password);
        if (login.challengeName === "NEW_PASSWORD_REQUIRED") {
            yield put({ type: LOGIN_FORCE_CHANGE_PASSWORD, data: login });
            history.push("/reset-password");
        } else {
            localStorage.setItem("authUser", login);
            if (login.signInUserSession.idToken.payload["cognito:groups"])
                localStorage.setItem("authUserGroup", login.signInUserSession.idToken.payload["cognito:groups"][0]);
            yield put({ type: LOGIN_REQUEST_SUCCESS, data: login });
            history.push("/");
        }
    } catch (err) {
        console.log(err);
        yield put({ type: LOGIN_REQUEST_ERROR, data: err });
    }
}

function* resetPasswordSaga({ data: { password, history } }) {
    try {
        const user = yield select(currentUser);
        const response = yield call([Auth, "completeNewPassword"], user, password);
        yield put({ type: LOGIN_REQUEST_SUCCESS, data: response });
        history.push("/");
    } catch (err) {
        console.log(err);
        yield put({ type: LOGIN_REQUEST_ERROR, data: err });
    }
}

function* logoutRequest({ data: history }) {
    try {
        yield call([Auth, "signOut"]);
        localStorage.removeItem("authUser");
        localStorage.removeItem("authUserGroup");
        yield put({ type: LOGOUT_REQUEST_SUCCESS });
        history.push("/");
    } catch (error) {
        console.log(error);
    }
}

function* getCurrentUser() {
    try {
        const user = yield call([Auth, "currentAuthenticatedUser"]);
        yield put({ type: SET_CURRENT_USER, data: user });
    } catch (err) {
        yield put({ type: NO_CURRENT_USER });
    }
}
function* initAuth({ data: { userPoolId, userPoolWebClientId } }) {
    try {
        const result = yield call([Amplify, "configure"], {
            Auth: {
                region: "eu-central-1",
                userPoolId: userPoolId,
                userPoolWebClientId: userPoolWebClientId,
            },
        });
        yield put({
            type: GET_CURRENT_USER,
        });
    } catch (error) {
        console.log(error);
    }
}
export default function* authSaga() {
    yield takeLatest(INIT_AUTH, initAuth);
    yield takeLatest(LOGIN_REQUEST, loginRequest);
    yield takeLatest(RESET_PASSWORD_REQUEST, resetPasswordSaga);
    yield takeLatest(LOGOUT_REQUEST, logoutRequest);
    yield takeLatest(GET_CURRENT_USER, getCurrentUser);
}
