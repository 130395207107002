import React from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";
import "./App.less";

import "fontsource-raleway";
const App = () => {
    return (
        <React.Fragment>
            <Router>
                <Switch>
                    {authProtectedRoutes.map((route, idx) => (
                        <AppRoute
                            path={route.path}
                            component={route.component}
                            key={idx}
                            isAuthProtected={true}
                            exact={route.exact}
                        />
                    ))}
                    {publicRoutes.map((route, idx) => (
                        <AppRoute
                            path={route.path}
                            component={route.component}
                            key={idx}
                            isAuthProtected={false}
                            exact={route.exact}
                        />
                    ))}
                </Switch>
            </Router>
        </React.Fragment>
    );
};

export default App;
