import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PagesComingsoon from "../pages/Shared/PagesComingsoon";

const AppRoute = ({ component: Component, isAuthProtected, isAuthenticated, event, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                if (event.currentEvent === null || event.loading) {
                    return <div className="triple-spinner" />;
                }
                if (!event.currentEvent.isOnline) return <PagesComingsoon />;
                if (event.currentEvent.needUserAuth) {
                    if (isAuthProtected === true && isAuthenticated === false) {
                        return <Redirect to="/login" />;
                    } else {
                        return <Component {...props} />;
                    }
                } else {
                    return <Component {...props} />;
                }
            }}
        />
    );
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    event: state.event,
});

export default connect(mapStateToProps, null)(AppRoute);
