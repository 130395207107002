export const GET_EVENT_SETTINGS_REQUEST = "GET_EVENT_SETTINGS_REQUEST";
export const GET_EVENT_SETTINGS_SUCCESS = "GET_EVENT_SETTINGS_SUCCESS";
export const GET_EVENT_SETTINGS_ERROR = "GET_EVENT_SETTINGS_ERROR";
export const INIT_API = "INIT_API";

export const getEventSettings = (url, history) => ({
    type: GET_EVENT_SETTINGS_REQUEST,
    data: { history, url },
});

export const getEventSuccess = (url) => ({
    type: GET_EVENT_SETTINGS_SUCCESS,
    data: url,
});

export const getEventError = (error) => ({
    type: GET_EVENT_SETTINGS_ERROR,
    data: error,
});
export const initializeAPI = () => ({
    type: INIT_API,
});
