/* eslint-disable import/no-anonymous-default-export */
import {
    LOGIN_REQUEST,
    LOGIN_REQUEST_SUCCESS,
    LOGIN_FORCE_CHANGE_PASSWORD,
    LOGIN_REQUEST_ERROR,
    SET_CURRENT_USER,
    CLEAR_CURRENT_USER,
    LOGOUT_REQUEST,
    LOGOUT_REQUEST_SUCCESS,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_ERROR,
    RESET_PASSWORD_SUCCESS,
    GET_CURRENT_USER,
    NO_CURRENT_USER,
} from "./actions";

const initialState = {
    error: null,
    loading: true,
    isAuthenticated: false,
    currentUser: null,
};

export default (state = initialState, { type, data }) => {
    switch (type) {
        case LOGIN_REQUEST:
            return { ...state, loading: true };
        case LOGIN_REQUEST_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                currentUser: data,
                loading: false,
            };
        case LOGIN_REQUEST_ERROR:
            return { ...state, error: data, loading: false };
        case LOGIN_FORCE_CHANGE_PASSWORD:
            return { ...state, currentUser: data, loading: false };
        case RESET_PASSWORD_REQUEST:
            return { ...state, loading: true };
        case RESET_PASSWORD_SUCCESS:
            return { ...state, loading: false };
        case RESET_PASSWORD_ERROR:
            return { ...state, error: data, loading: false };
        case LOGOUT_REQUEST:
            return {
                ...state,
                isAuthenticated: false,
                currentUser: null,
                loading: true,
            };
        case LOGOUT_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case GET_CURRENT_USER:
            return { ...state, loading: true };
        case NO_CURRENT_USER:
            return { ...state, loading: false };
        case SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: true,
                currentUser: data,
                loading: false,
            };
        case CLEAR_CURRENT_USER:
            return { ...state, isAuthenticated: false, currentUser: null };
        default:
            return { ...state };
    }
};
