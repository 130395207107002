import React, { Component } from "react";
// import micezoneLogo from "../../assets/images/micezone.png";

class PagesMaintenance extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <React.Fragment>
                {/* <img className="micezonelogo" src={micezoneLogo} alt="mice.zone logo" /> */}
            </React.Fragment>
        );
    }
}

export default PagesMaintenance;
