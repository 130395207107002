import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { logoutRequest } from "../../store/actions";

const Logout = (props) => {
    const history = useHistory();
    props.logoutRequest(history);
    return <React.Fragment></React.Fragment>;
};

export default connect(null, { logoutRequest })(Logout);
